import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerServices from "../components/services/banner/services.component"
import AllServices from "../components/services/services.all.component"

const Services = () => {
  return (
    <>
      <Layout>
        <Seo title="Services" />
        <BannerServices />
        <AllServices />
      </Layout>
    </>
  )
}

export default Services
