import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const AllServices = () => {
  return (
    <div className="sub-container">
      <div className="section sub-container center flex column align-center">
        <h6>Our Services</h6>
        <h3>General & Cosmetics</h3>
      </div>
      <div className="flex wrap space-between">
        <div className="services-box">
          <StaticImage src="../../images/service1.png" />
          <div class="overlay">
            <div class="text">Services</div>
          </div>
        </div>
        <div className="services-box">
          <StaticImage src="../../images/service1.png" />
          <div class="overlay">
            <div class="text">Services</div>
          </div>
        </div>
        <div className="services-box">
          <StaticImage src="../../images/service1.png" />
          <div class="overlay">
            <div class="text">Services</div>
          </div>
        </div>
        <div className="services-box">
          <StaticImage src="../../images/service1.png" />
          <div class="overlay">
            <div class="text">Services</div>
          </div>
        </div>
        <div className="services-box">
          <StaticImage src="../../images/service1.png" />
          <div class="overlay">
            <div class="text">Services</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllServices
